<template>
  <div class="feedback">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left"/>
        <span>订单异议反馈</span>
      </div>
    </div>
    <div class="content">
      <div class="title"></div>
      <div class="desc">本平台仅提供客户与商家支付技术平台支持，不参与课程交付及售后问题反馈及解决，如客户对订单还款有异议，请与商家协商沟通，感谢理解与支持。</div>
      <div class="after-sale-phone" v-if="feedBackInfo.customer_service_phone">
        商家售后电话：{{ feedBackInfo.customer_service_phone }}
      </div>
    </div>
  </div>
</template>

<script>
import {businessFeedBackInfo} from '@/api/business.js'

export default {
  name: 'feedback',

  data() {
    return {
        feedBackInfo:{}
    };
  },

  mounted() {
    this.initData()
  },

  methods: {
    async initData(){
      const {code,data} = await businessFeedBackInfo()
      if(code == 200){
        this.feedBackInfo = data
      }
    },
    onClickLeft(){
      this.$router.push('/my')
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback{
  height: 100%;   
  width: 100%;
  background: #f5f5f5;
  .go-back{
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    height: 100px;
  }
  .content{
    padding: 30px 40px;
    .desc{
      margin-top: 20px;
      font-size: 26px;
      line-height: 60px;
      text-align: justify;
    }
    .after-sale-phone{
        font-size: 26px;
        line-height: 60px;
        text-align: justify;
    }
  }
}
</style>